import React, { useEffect, useState } from "react";
import { convertDateInStandardFormat } from "../../../utils/formatting/date.js";
import { Modal, Box, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Checkbox } from "@mui/material";
import cogoToast from "cogo-toast";
import { getCall, postCall } from "../../../Api/axios.js";
import { useTheme } from "@mui/material/styles";

const PendingSettlementModal = ({ open, onClose, loading, settlementDetails, error }) => {
    const theme = useTheme();
    const [groupedData, setGroupedData] = useState([]);
    const [selectedItems, setSelectedItems] = useState({}); // State to track selected items for each group

    useEffect(() => {
        if (settlementDetails) {
            // Group data by collectorAppId
            const grouped = settlementDetails.reduce((acc, curr) => {
                var key = curr.collectorAppId;
                if (curr.settlementId) {
                    key += "##" + curr.settlementId;
                }
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr);
                return acc;
            }, {});

            // Convert grouped data into an array for rendering
            setGroupedData(Object.entries(grouped).map(([collectorAppId, items]) => ({
                collectorAppId,
                items
            })));
        }
    }, [settlementDetails]);

    const handleSelectRow = (groupId, itemId) => {
        setSelectedItems((prevSelected) => {
            const groupSelected = prevSelected[groupId] || [];
            if (groupSelected.includes(itemId)) {
                return {
                    ...prevSelected,
                    [groupId]: groupSelected.filter((id) => id !== itemId)
                }; // Remove if already selected
            }
            return {
                ...prevSelected,
                [groupId]: [...groupSelected, itemId] // Add if not selected
            };
        });
    };

    const handleGroupAssign = (groupId) => {
        const selectedInGroup = selectedItems[groupId] || [];
        var body = `${selectedInGroup.join(', ')}`;
        body = body.split(',').map(item => item.trim());
        postCall(`/api/v1/seller/assign`, body)
            .then((resp) => {
                if (resp?.status === 200) {
                    cogoToast.success("Assigned successfully");
                } else {
                    cogoToast.error(resp.message);
                }
            })
            .catch((error) => {
                console.log(error);
                cogoToast.error(error.response.data.error);
            });
    }

    const handleSettlement = (groupId, action) => {
        const settlementId = groupId.split("##")[1];
        postCall(`/api/v1/seller/${settlementId}/${action}`, {})
            .then((resp) => {
                if (resp?.status === 200) {
                    cogoToast.success("Action taken successfully");
                } else {
                    cogoToast.error(resp.message);
                }
            })
            .catch((error) => {
                console.log(error);
                cogoToast.error(error.response.data.error);
            });
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%", // Center vertically
                    left: "50%", // Center horizontally
                    transform: "translate(-50%, -50%)", // Adjust to center the modal
                    width: "85vw", // 75% of the window width
                    height: "85vh", // 75% of the window height
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    overflow: "auto", // Ensure the content is scrollable if necessary
                }}
            >
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : groupedData.length > 0 ? (
                    <Box>
                        <div className="mb-4 flex flex-row justify-between items-center">
                            <label style={{ color: theme.palette.primary.main }} className="font-semibold text-2xl">
                                Pending Settlement Details
                            </label>
                        </div>
                        {groupedData.map((group) => (
                            <Box key={group.collectorAppId} sx={{ mb: 3 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h7" sx={{ mt: 2, fontWeight: 'bold' }}>
                                        Collector: {group.collectorAppId}
                                    </Typography>

                                    {!group.collectorAppId.includes("##") && (
                                        <Box sx={{ marginLeft: 'auto' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleGroupAssign(group.collectorAppId, 'settle')}
                                                disabled={!selectedItems[group.collectorAppId]?.length} // Disable if no items selected for this group
                                                sx={{ mt: 2, mr: 1 }} // Add margin-right to space out buttons
                                            >
                                                ASSIGN FOR SETTLEMENT
                                            </Button>
                                        </Box>
                                    )}
                                    {group.collectorAppId.includes("##") && (
                                        <Box sx={{ marginLeft: 'auto' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleSettlement(group.collectorAppId, 'settle')}
                                                //disabled={!selectedItems[group.collectorAppId]?.length} // Disable if no items selected for this group
                                                sx={{ mt: 2, mr: 1 }} // Add margin-right to space out buttons
                                            >
                                                SETTLE
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleSettlement(group.collectorAppId, 'report')}
                                                //disabled={!selectedItems[group.collectorAppId]?.length} // Disable if no items selected for this group
                                                sx={{ mt: 2, mr: 1 }}
                                            >
                                                REPORT
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleSettlement(group.collectorAppId, 'recon')}
                                                //disabled={!selectedItems[group.collectorAppId]?.length} // Disable if no items selected for this group
                                                sx={{ mt: 2 }}
                                            >
                                                RECON
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                                <TableContainer
                                    sx={{
                                        maxHeight: 400,
                                        overflowY: 'auto',
                                        overflowX: 'auto', // Enables horizontal scrolling
                                        display: 'block',  // Ensures table respects the container's scroll behavior
                                        whiteSpace: 'nowrap', // Prevents table cells from wrapping and maintains the horizontal layout
                                    }}
                                >
                                    <Table sx={{ minWidth: 1200 }}>
                                        <TableHead>
                                            <TableRow>
                                                {!group.collectorAppId.includes("##") && <TableCell>Select</TableCell>}
                                                <TableCell>Order ID</TableCell>
                                                <TableCell title="Fulfilment State">FS</TableCell>
                                                <TableCell >Recon Accord</TableCell>
                                                <TableCell title="Recon Status">RS</TableCell>
                                                <TableCell title="Inter Participant Status">IPS</TableCell>
                                                <TableCell title="Self Status">SS</TableCell>
                                                <TableCell >Error</TableCell>
                                                <TableCell title="Total Amount">TA</TableCell>
                                                <TableCell title="Total Amount Difference">TAD</TableCell>
                                                <TableCell title="Self Amount">SA</TableCell>
                                                <TableCell title="Buyer Commission">BC</TableCell>
                                                <TableCell title="Buyer Commission Difference">BCD</TableCell>
                                                <TableCell title="Withholding Amount">WA</TableCell>
                                                <TableCell title="Withholding Amount Difference">WAD</TableCell>
                                                <TableCell title="Tax Deducted at Source">TDS</TableCell>
                                                <TableCell title="Tax Deducted at Source Difference">TDSD</TableCell>
                                                <TableCell title="Tax Collected at Source">TCS</TableCell>
                                                <TableCell title="Tax Collected at Source Difference">TCSD</TableCell>
                                                <TableCell>Created At</TableCell>
                                                <TableCell>Updated At</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {group.items.map((item) => (
                                                <TableRow key={item._id}>
                                                    {!group.collectorAppId.includes("##") && (
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={selectedItems[group.collectorAppId]?.includes(item._id)}
                                                                onChange={() => handleSelectRow(group.collectorAppId, item._id)}
                                                            />
                                                        </TableCell>
                                                    )}
                                                    <TableCell>{item.orderId}</TableCell>
                                                    <TableCell>{item.fulfilmentState}</TableCell>
                                                    <TableCell>
                                                        {item.reconAccord === true ? (
                                                            <Typography sx={{ color: 'green', fontWeight: 'bold' }}>Yes</Typography>
                                                        ) : item.reconAccord === false ? (
                                                            <Typography sx={{ color: 'red', fontWeight: 'bold' }}>No</Typography>
                                                        ) : (
                                                            <Typography>N/A</Typography>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>{item.reconStatus}</TableCell>
                                                    <TableCell>{item.interParticipantStatus}</TableCell>
                                                    <TableCell>{item.selfStatus}</TableCell>
                                                    <TableCell>{item.errorMessage}</TableCell>
                                                    <TableCell>{item.totalAmount?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.totalAmountDiff?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.selfAmount?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.buyerCommission?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.buyerCommissionDiff?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.withholdingAmount?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.withholdingAmountDiff?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.tds?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.tdsDiff?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.tcs?.toFixed(2)}</TableCell>
                                                    <TableCell>{item.tcsDiff?.toFixed(2)}</TableCell>
                                                    <TableCell>{convertDateInStandardFormat(item.entryDate)}</TableCell>
                                                    <TableCell>{convertDateInStandardFormat(item.updateDate)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Typography>No details available.</Typography>
                )}
            </Box>
        </Modal>
    );
};

export default PendingSettlementModal;
