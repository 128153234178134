import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Button,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { postCall, getCall } from "../../../Api/axios";
import ActionModal from "./ActionModal";
import { convertDateInStandardFormat, convertDateOnly } from "../../../utils/formatting/date.js";


const StyledTableCell = styled(TableCell)({
    "&.MuiTableCell-root": {
        fontWeight: "bold",
    },
});

const ThreeDotsMenu = (props) => {
    const { row, view } = props;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activityDetails, setActivityDetails] = useState(null);
    const [error, setError] = useState(null);

    const handleOpen = async (status) => {
        setOpen(true);
        setLoading(true);
        setError(null);

        try {
            const body = { "bapId": `${row?.bapId}` };
            const response = await postCall(`/api/v1/seller/${row?.transactionId}/${status}/activity`, body);
            setActivityDetails(response.data);
        } catch (err) {
            setError("Failed to fetch activity details. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setActivityDetails(null);
        setError(null);
    };

    const buttonText = "VIEW";

    return (
        <>
            <Button variant="contained" size="small" onClick={() => handleOpen(view)}>
                {buttonText}
            </Button>
            <ActionModal
                open={open}
                onClose={handleClose}
                loading={loading}
                actions={activityDetails}
                error={error}
            />
            {/* {view == "pending" && <PendingSettlementModal
          open={open}
          onClose={handleClose}
          loading={loading}
          settlementDetails={settlementDetails}
          error={error}
        />}
        {view == "settled" && <ActionModal
          open={open}
          onClose={handleClose}
          loading={loading}
          settlementDetails={settlementDetails}
          error={error}
        />} */}
        </>
    );
};


const ActivityTable = (props) => {
    const {
        view,
        columns,
        data,
        page,
        rowsPerPage,
        totalRecords,
        handlePageChange,
        handleRowsPerPageChange,
    } = props;
    const navigate = useNavigate();

    const onPageChange = (event, newPage) => {
        handlePageChange(newPage);
    };

    const onRowsPerPageChange = (event) => {
        handleRowsPerPageChange(parseInt(event.target.value, 10));
        handlePageChange(0);
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        {columns.map((column) => (
                            <StyledTableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                    minWidth: column.minWidth,
                                    backgroundColor: "#1976d2",
                                    color: "#fff",
                                }}
                            >
                                {column.label}
                            </StyledTableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow hover tabIndex={-1} key={index}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    if (column.id == "transactionDate") {
                                        return (
                                            <TableCell style={styles.tableCell} key={column.id} align={column.align}>
                                                {view === "rejection" && convertDateInStandardFormat(value)}
                                                {view !== "rejection" && convertDateOnly(value)}
                                            </TableCell>
                                        );
                                    }
                                    if (column.id === "Action") {
                                        return (
                                            <TableCell key={column.id} align={"left"}>
                                                <ThreeDotsMenu view={view} row={row} />
                                            </TableCell>
                                        );
                                    }
                                    return (
                                        <TableCell style={styles.tableCell} key={column.id} align={column.align}>
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </Paper>
    );
};

export default ActivityTable;

const styles = {
    tableCell: {
        padding: '6px',
        whiteSpace: 'nowrap', // Prevent line breaks
        //overflow: 'hidden', // Hide overflowed text
        //textOverflow: 'ellipsis', // Add ellipsis (...) for overflowed text
        minWidth: '150px', // Optional: Adjust based on the available space
    }
}